// assets
import ao_appscreens1 from './assets/appscreens1.png';
import ao_kiosk1 from './assets/kiosk1.png';
import ao_kiosk2 from './assets/kiosk2.png';
import cover from './assets/cover.png';
import logo from './assets/mta-logo.png';
import MTAcover from './assets/cover-photo.png';
import MTAmockups from './assets/mta-mockups.png';
import MTAsketches from './assets/mta-sketches.png';
import MTAwireframes from './assets/mta-wireframes.png';

// components
import { ImgContainer } from '../../components/overview-section/OverviewSection.styles';
import { Heading, TextWithHeading } from '../../components/overview-section/components/section-text/SectionText.styles';
import Text from '../../components/overview-section/components/section-text/components/text/Text.js';
import List from '../../components/overview-section/components/section-text/components/list/List.js';
import ImgHolder from '../../components/overview-section/components/img-holder/ImgHolder';
import OverviewHeader from '../../components/overview-header/OverviewHeader';
import OverviewSection from '../../components/overview-section/OverviewSection';
import ProjectOverview from '../../components/project-overview/ProjectOverview.js';

export default function MTAOverview() {
  return (
    <ProjectOverview>
      <OverviewHeader logo={logo} cover={cover} title={'MTA Card Management App'} description={"A mobile MTA app to quickly and easily purchase and refill MetroCards for the New York City subway"}/>
      
      <ImgHolder src={MTAcover} alt={"App overview"} width={80} />

      <OverviewSection sectionNumber={"01"} title={"ROLE"}>
        <TextWithHeading>
          <Heading>UI/UX Designer</Heading>
          <List>
            <ul>
              <li>Sketched priliminary designs</li>
              <li>Designed wireframes, mockups and prototype in Adobe XD</li>
            </ul>
          </List>
        </TextWithHeading>
      </OverviewSection>

      <OverviewSection sectionNumber={"02"} title={"GOALS"}>
        <List>
          <ul>
            <li>Design an interface that allows users to quickly and effortlessly reload their MetroCard balance</li>
            <li>Create a high-fidelity prototype that conveys app features</li>
            <li>Design an interface consistent with existing MTA design systems to flatten the user learning curve</li>
            <li>Pay homage to the original MetroCard</li>
          </ul>
        </List>
      </OverviewSection>

      <OverviewSection sectionNumber={"03"} title={"DESIGN STAGES"}>
        <ImgContainer>
          <ImgHolder src={ao_kiosk1} alt={"MetroCard kiosk"} width={100} caption={"I drew design inspiration from the interface of existing MetroCard kiosks. These machines are used by millions of New Yorkers and tourists, providing a straightfoward and intuitive experience for commuters."} />
          <ImgHolder src={MTAsketches} alt={"MTA app sketches"} width={100} caption={"The initial sketches have many of the same screens from the kiosk user flow, but are now optimized for mobile screen sizes."} />
        </ImgContainer>  
        <ImgContainer>
          <ImgHolder src={MTAwireframes} alt={"MTA app wireframes"} width={100} caption={"Because the target demographic of users for this app is so broad, a primary focus was simplicity. The app is sectioned into 3 parts, with the Home/payment screen being the most prominent."} />
          <ImgHolder src={MTAmockups} alt={"MTA app mockups"} width={100} caption={"The mockups for the prototype were influenced by the existing color palette and typography of the MTA."} />
        </ImgContainer>
      </OverviewSection>
      <OverviewSection sectionNumber={"04"} title={"OVERVIEW"}>
        <Text>
          By studying the existing MTA design system, this app provides a cohesive brand experience that feel be familiar
          to anyone who has previously used the self-serve kiosks.
        </Text>
        <ImgContainer>
          <ImgHolder src={ao_appscreens1} alt="refill screen" width={100} caption={"Adapting to evolving methods of payment, quick pay features such as Apple Pay have been included in the prototype."}/>
          <ImgHolder src={ao_kiosk2} alt="kiosk UI" width={100} />
        </ImgContainer>
      </OverviewSection>
      <OverviewSection sectionNumber={"05"} title={"CLOSING THOUGHTS"}>
        <Text>
          I designed this app before the MTA implemented the OMNY tap-to-pay system. I see less of a need for 
          it now due to widespread adoption of Apple/Google/Android Pay, as well as the new fare-capping program
          that only changes riders up to $34 dollars in fares on a weekly basis. Nonetheless, this case study was useful
          in refining my approach to design system work to learning to maintain a cohesive user experience. 
        </Text>
        <Text>
            While this app may not a necessity for the majority of commuters, it maintains value for commuters who do 
            not have access to their own debit/credit card for tap-to-pay purposes. This app can also be used for
            transportation stipends, where employers can pre-load and manage multiple MetroCards at a time.
        </Text>
      </OverviewSection>
    </ProjectOverview>
  )
}