import styled from "styled-components";

export const ProjectOverviewContainer = styled.div`

    @media only screen and (max-width: 668px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 20px 30px;

        @media only screen and (max-width: 375px) {
            gap: 35px;
        }
    }
    @media only screen and (min-width: 668px) and (max-width: 968px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
        height: 900px;
        margin: 50px 50px;
    }
    @media only screen and (min-width: 968px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 50px 60px;
    }
`