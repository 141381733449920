import styled from "styled-components";

export const PresenceContainer = styled.div`
    align-items: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    width: 100vw;
`
export const BGGradient = styled.div`
    background: linear-gradient(
        45deg,
        rgba(219,120,212,0.8) 0%,
        rgba(32,172,232,0.8) 100%
    );
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: -10;
`
export const LogoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 100px 0px;

    h5 {
        font-weight: 500;
    }

    img {
        width: 300px;
    }

    @media only screen and (max-width: 668px) {
        margin: 50px 0px;

        img {
            width: 200px;
        }
        
    }
`
export const CoverContainer = styled.div`
    align-items: center;
    display: flex;
    height: fit-content;
    justify-content: center;
`
export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 40px;

    @media only screen and (max-width: 468px) {
        flex-direction: column;
    }
    
`
export const EntriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 0px 100px;

    @media only screen and (max-width: 668px) {
        padding: 0px 20px;
    }

    @media only screen and (max-width: 768px) {
        padding: 0px 50px;
    }

    @media only screen and (max-width: 868px) {
        padding: 0px 30px;
    }
`
export const EntryDualColContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 100px;

    @media only screen and (max-width: 668px) {
        flex-direction: column;
        gap: 20px;
    }

    @media only screen and (max-width: 668px) {
        flex-direction: column;
        gap: 20px;
    }
`
export const EntryColumn = styled.div`
    display: flex;
    width: ${props => props.$width}%;

    @media only screen and (max-width: 668px) {
        width: 100%;
    }
`
export const ProcessEntry = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .entry-header {
        font-size: 24px;
        margin: 0;
    }

    .entry-subheader {
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        margin: 0;
    }

    .project-components-container {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: 100%;


        @media only screen and (max-width: 868px) {
        flex-direction: column;

        .project-components-col {
            width: 100%;
        }

    }
    }

    ul {
        line-height: 24px;
        padding-left: 20px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }
`
export const ComponentContainer = styled.div`
    width: 100%;

    .project-components-col {
        border: 1px solid #FFFFFF;
        border-radius: 14px;
        box-sizing: border-box;
        height: 100%;
        padding: 16px;
        width: 100%;

        h3 {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 868px) {
        flex-direction: column;
    }
`
export const YouTubeContainer = styled.div`
    position: relative;

    iframe {
        width: 100%;

        @media only screen and (min-width: 868px) {
            height: 500px;
        }
    }
`
