import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// styles
import './App.css';

// pages
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import MotionGraphics from './pages/motion-graphics/MotionGraphics';
import MTAOverview from './pages/product-design/projects/mta-overview/MTAOverview';
import PlatformOverview from './pages/product-design/projects/platform-overview/PlatformOverview';
import PresenceClothing from './pages/presence-clothing/PresenceClothing';
import PresenceOverview from './pages/product-design/projects/presence-overview/PresenceOverview';
import PullVaultOverview from './pages/product-design/projects/pull-vault-overview/PullVaultOverview';
import ProductDesign from './pages/product-design/ProductDesign';
import Work from './pages/work/Work';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("scrolled to top")
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} >
            <Route path="/" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
          <Route path="/motion-graphics" element={<MotionGraphics />} />
          <Route path="/product-design" element={<ProductDesign />}>
            <Route path='pull-vault' element={<PullVaultOverview />} />
            <Route path='platform' element={<PlatformOverview />} />
            <Route path='mta' element={<MTAOverview />} />
            <Route path='presence' element={<PresenceOverview />} />
          </Route>
          <Route path="/presence" element={<PresenceClothing />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;