import styled from "styled-components";

export const HeaderContainer = styled.div`
    align-items: center;
    color: #FFFFFF;
    display: flex;
    position: fixed;
    width: 100%;

    .logo-container {
        cursor: pointer;
    }

    @media only screen and (max-width: 399px) {
        flex-direction: column;
        gap: 30px;
        height: 240px;
        justify-content: center;
    }
    @media only screen and (min-width: 400px) and (max-width: 668px) {
        flex-direction: column;
        gap: 40px;
        height: 260px;
        justify-content: center;
    }
    @media only screen and (min-width: 669px) and (max-width: 768px) {
        height: 160px;
        justify-content: space-between;
    
        .logo-container {
            margin-left: 100px;
            width: 100px;
        }
    }
    @media only screen and (min-width: 769px) {
        height: 200px;
        justify-content: space-between;
    
        .logo-container {
            margin-left: 100px;
        }
    }
`
export const NavContainer = styled.div`
    align-items: center;
    display: flex;

    .nav-sections {
        display: flex;
        position: relative;

        .dot {
            background: #FFFFFF;
            border-radius: 100%;
            bottom: -10px;
            height: 8px;
            position: absolute;
            transition: 0.3s ease-in-out;
            width: 8px;
        }
        .work {
            left: 50px;
        }

        .about {
            left: 150px;
        }
    }
    @media only screen and (max-width: 668px) {
        flex-direction: column;
        gap: 40px;
    }
    @media only screen and (min-width: 669px) and (max-width: 768px) {
        gap: 40px;
        margin-right: 100px;
    }
    @media only screen and (min-width: 769px) {
        gap: 40px;
        margin-right: 100px;
    }
`
export const LinksContainer = styled.div`
    @media only screen and (max-width: 668px) {
        display: none;
    }

`