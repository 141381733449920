import styled from "styled-components";

export const SecondaryBtnContainer = styled.div`
    align-items: center;
    background-color: ${props => props.$bgCol};
    border: ${props => props.$borderCol ? `2px solid ${props.$borderCol}` : 'none'};
    border-radius: 24px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    transition: 0.4s ease-in-out;
    width: 176px;

    h4 {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;

        @media only screen and (max-width: 868px) {
            font-size: 15px;
        }
    }

    &:hover {
        transform: scale(1.05);
        transition: 0.4s ease-in-out;
    }

    @media only screen and (max-width: 868px) {
        border-radius: 20px;
        height: 40px;
        width: 146.67px;
    }
`