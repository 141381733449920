import styled from "styled-components";

// assets
import instagramIcon from '../../assets/instagram-icon-inactive.png';
import linkedinIcon from '../../assets/linkedin-icon-inactive.png';

export const SocialLinksContainer = styled.div`
    display: flex;
    gap: 30px;

    a {
        #instagram-social-link {
            background-image: url(${instagramIcon});
            background-size: 24px;
            height: 24px;
            object-fit: cover;
            transition: 0.4s ease-in-out;
            width: 24px;
        }

        #instagram-social-link:hover {
            transform: scale(1.2);
            transition: 0.4s ease-in-out;
        }

        #linkedin-social-link {
            background-image: url(${linkedinIcon});
            background-size: 24px;
            height: 24px;
            object-fit: cover;
            transition: 0.4s ease-in-out;
            width: 24px;
        }

        #linkedin-social-link:hover {
            transform: scale(1.2);
            transition: 0.4s ease-in-out;
        }
    }
`