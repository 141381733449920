// assets
import logo from './assets/platform-logo.png';
import cover from './assets/cover.png';

// components
import ProjectOverview from '../../components/project-overview/ProjectOverview.js';
import OverviewHeader from '../../components/overview-header/OverviewHeader';

export default function PlatformOverview() {
  return (
    <ProjectOverview>
      <OverviewHeader logo={logo} cover={cover} title={"Platform Website Buidler"} description={"The Platform Website Builder is designed for businesses to create websites that display all essental information at a glance. More information on this project coming soon!"}/>
    </ProjectOverview>
  )
}
