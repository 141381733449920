// assets
import signature from '../../assets/signature.png';

// components
import SecondaryBtn from '../../components/btns/SecondaryBtn/SecondaryBtn';
// import { Heading, TextWithHeading } from '../product-design/components/overview-section/components/section-text/SectionText.styles.js';
// import List from '../product-design/components/overview-section/components/section-text/components/list/List.js';

// styles
import {
  AboutContainer,
  AboutDetails,
  AboutBio,
  // AboutSkills
} from './About.styles.js'

// components
// import OverviewSection from '../product-design/components/overview-section/OverviewSection.js';
import ElementWrapper from '../../components/element-wrapper/ElementWrapper.js';

export default function About() {

  return (
    <AboutContainer>
      <AboutDetails>
        <AboutBio>
          <ElementWrapper delay={0.1}>
            <a target='_blank' rel='noopener noreferrer' href="https://drive.google.com/file/d/14apSMrwajJO1wSK1HweAyDsCP2kv9owb/view?usp=sharing">
              <SecondaryBtn label='VIEW RESUME' />
            </a>
          </ElementWrapper>
          <ElementWrapper delay={0.2}>
            <h5>
              A bit about me...<br /><br />
              
              I am an experiential designer passionate about fostering meaningful engagement
              through interaction. I specialize in product design and marketing through the mediums of web
              development and augmented reality. My design ethics incoporate a human-first approach to addressing
              user needs, and utilize design principles that provide intuitively immersive
              experiences.<br/><br/>
              
              I graduated from New York University's Tandon School of Engineering with a bachelor
              of science in Integrated Design & Media (IDM). IDM is a degree which fosters creative practice,
              design research and multidisciplinary experimentation with emerging media technologies. Through this
              major I have acquired digital skill sets including UX design, graphic design, motion
              graphics, web development, 3D modeling, and augmented reality. Utilizing these fields in
              conjunction with each other helps inform and validate design decisions in an interdisciplinary
              manner.<br/><br/>

              Combining my knowledge of web development and UX design, my projects include the design and development
              of web applications. I am currently (January, 2024) developing an app for tracking and selling collectibles,
              and a website builder that enhances small business engagement with customers.
              <br/><br/>

              My career as a designer is defined by creating products that alleviate pain
              points and provide impactful utility. My successes come in the form of positive impact, and my
              failures come in the form of learned lessons. In designing digital products within an increasingly
              digitized world, my philosophy endorses digital products that augment existing ways of life
              without impeding on fundamental aspects of the human experience. To balance screen time, I
              meditate and spend time outdoors.
              <br/><br/>
            </h5>
            <img style={{ width:"60px" }} src={signature} alt='kiva loor' />
          </ElementWrapper>
        </AboutBio>
        {/* <AboutSkills>
          <OverviewSection>   
            <TextWithHeading>
              <Heading>Hi</Heading>
              <List>
                <ul>
                  <li>
                    1
                  </li>
                  <li>
                    2
                  </li>
                </ul>
              </List>
            </TextWithHeading>
          </OverviewSection>
        </AboutSkills> */}
      </AboutDetails>
    </AboutContainer>
  )
}