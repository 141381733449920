import styled from "styled-components";

export const HeaderContainer = styled.div`
    align-items: center;
    gap: 100px;
    width: 100%;

    @media only screen and (max-width: 375px) {
        gap: 30px;
    }

    @media only screen and (max-width: 768px) {
        gap: 30px;
    }

    @media only screen and (max-width: 968px) {
        display: flex;
        flex-direction: column;
    }
    @media only screen and (min-width: 968px) {
        display: flex;
    }
`
export const HeaderCol = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;

    @media only screen and (max-width: 968px) {
        width: 100%;
    }
`
export const ProjectDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
export const DetailsTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
export const LogoContainer = styled.div`
    display: flex;
    height: 30px;

    img {
        height: 100%;
        position: relative;
    }
`
export const ProjectDescription = styled.h3`
    border-left: 1px solid #464646;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    max-width: 500px;
    padding-left: 20px;
    width: calc(100% - 20px); 
`
export const CoverImg = styled.div`
    align-items: center;
    display: flex;
    height: 88%;
    justify-content: start;
    position: relative;
    width: 88%;

    @media only screen and (max-width: 375px) {
        height: 100%;
        width: 100%;
    }

    @media only screen and (max-width: 568px) {
        justify-content: center;
        height: 100%;
        width: 100%;
    }
`