import styled from "styled-components";

export const SectionTextContainer = styled.div`
    @media only screen and (max-width: 668px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    @media only screen and (min-width: 668px) and (max-width: 968px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    @media only screen and (min-width: 968px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`
export const Heading = styled.h3`
    font-style: italic;
    font-weight: 600;

    @media only screen and (max-width: 375px) {
        font-size: 16px;
    }
`
export const TextWithHeading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media only screen and (max-width: 375px) {
        gap: 14px;
    }
`
