// styles
import './Contact.scss'

// components
import ContactPanel from './components/contact-panel/ContactPanel'

export default function Contact() {
  return (
    <div className='contact-container'>
        <h1>CONTACT</h1>
        <div className='contact-panels-container'>
          <ContactPanel title={'EMAIL'}/>
          <ContactPanel title={'INSTAGRAM'}/>
          <ContactPanel title={'LINKEDIN'}/>
        </div>
    </div>
  )
}
