import styled from "styled-components";

export const WorkPanelContainer = styled.div`
    align-items: center;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    transition: 0.4s ease-in-out;
    width: 100%;

    h1 {
        transform: scale(1);
        transition: 0.4s ease-in-out;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #deeeff;
            transform: scale(1.025);
        }

        &:hover h1 {
            transform: scale(0.925);
            transition: 0.4s ease-in-out;
        }
    }
`
export const Title = styled.h1`
    color: #2b3b54;
    font-size: 24px;
    text-align: center;
    max-width: 66.67%;
    padding: 12px;

    @media only screen and (min-width: 375px) {
        font-size: 14px;
    }
    @media only screen and (min-width: 421px) {
        font-size: 14px;
    }
    @media only screen and (min-width: 568px) {
        font-size: 16px;
    }
    @media only screen and (min-width: 868px) {
        font-size: 18px;
    }
    @media only screen and (min-width: 1000px) {
        font-size: 24px;
    }

`







