import styled from "styled-components";

export const CategoryRowContainer = styled.div`
    @media only screen and (max-width: 1080px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    
        .category-title {
            
            h1 {
                color: #FFFFFF;
                font-size: 20px;
                
            }
        }
    
        .category-panels-container {
            display: flex;
            overflow-x: auto;
            gap: 20px;

            div:last-child {
                margin-right: 20px;
            }
    
            .category-panel-wide {
                background-color: rgba(255,255,255,0.2);
                border-radius: 2px;
                box-sizing: border-box;
                cursor: pointer;
                height: 157px;
                min-width: 280px;
                transition: 0.4s ease-in-out;
                width: 280px;
            }
    
            .category-panel-wide:hover {
                border: 2px solid #FFFFFF;
                transition: 0.4s ease-in-out;      
            }
    
            .category-panel-tall {
                background-color: rgba(255,255,255,0.2);
                border: 2px solid rgba(255,255,255,0.2);
                border-radius: 2px;
                cursor: pointer;
                height: 267px;
                min-width: 179px;
                width: 179px;
            }

            .category-panel-tall:hover {
                border: 2px solid #FFFFFF;
                transition: 0.4s ease-in-out;      
            }
        }
    }
    @media only screen and (min-width: 1080px) {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .category-title {
            
            h1 {
                color: #FFFFFF;
                font-size: 20px;
                
            }
        }

        .category-panels-container {
            display: flex;
            gap: 20px;
            overflow-x: auto;

            .category-panel-wide {
                background-color: rgba(255,255,255,0.2);
                border-radius: 2px;
                box-sizing: border-box;
                cursor: pointer;
                height: 157px;
                transition: 0.4s ease-in-out;
                min-width: 280px;
                width: 280px;
            }

            .category-panel-wide:hover {
                border: 2px solid #FFFFFF;
                transition: 0.4s ease-in-out;      
            }

            .category-panel-tall {
                background-color: rgba(255,255,255,0.2);
                border: 2px solid rgba(255,255,255,0.2);
                border-radius: 2px;
                cursor: pointer;
                height: 267px;
                min-width: 179px;
                width: 179px;
            }

            .category-panel-tall:hover {
                border: 2px solid #FFFFFF;
                transition: 0.4s ease-in-out;      
            }
        }
    }
`