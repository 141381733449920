import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// assets
import c1_img from './assets/c1-title-img.png'
import mgLogo from './assets/motion-graphics-logo.png'
import play_icon from './assets/play-icon.png'

// For you video covers
import mainCover from './assets/main-cover.mp4'
import vid1Cover from './assets/video-covers/vid1-cover.png'
import vid2Cover from './assets/video-covers/vid2-cover.png'
import vid3Cover from './assets/video-covers/vid3-cover.png'
import vid4Cover from './assets/video-covers/vid4-cover.png'

// Just added video covers
import vid5Cover from './assets/video-covers/vid5-cover.png'
import vid6cover from './assets/video-covers/vid6-cover.png'

// styles
import { MotionGraphicsContainer } from './MotionGraphics.styles.js'

// components
import CategoryRow from './components/category-row/CategoryRow'
import ElementWrapper from '../../components/element-wrapper/ElementWrapper.js';
import HBOBtn from './components/btns/HBOBtn'
import OverlayPlayer from './components/overlay-player/OverlayPlayer'

export default function MotionGraphics() {
  const navigate = useNavigate()
  const forYouDetails = [{id: '0', cover: vid1Cover}, {id: '1', cover: vid2Cover}, {id: 2, cover: vid3Cover}, {id: 3, cover: vid4Cover}]
  const justAddedDetails = [{id: 4, cover: vid6cover}, {id: 5, cover: vid5Cover}]
  const videoLinks = [
    'https://www.youtube.com/embed/fmT-pj-Yk8U',
    'https://www.youtube.com/embed/I3rn-_UOYdc',
    'https://www.youtube.com/embed/xiUELK9Ph1M',
    'https://www.youtube.com/embed/-SIG0xsAbDg',
    'https://www.youtube.com/embed/Rqnrm19tvVM',
    'https://www.youtube.com/embed/m1azs2xirH8'
  ]

  const [overlayPlayerIndex, setOverlayPlayerIndex] = useState(null)

  useEffect(() => {
    console.log(overlayPlayerIndex)
  }, [overlayPlayerIndex])

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <MotionGraphicsContainer>
      {overlayPlayerIndex &&
        <>
          <div onClick={() => setOverlayPlayerIndex(null)} className='overlay-player-btn-container'>
            <HBOBtn label='BACK' type='no-fill' />
          </div>
          <OverlayPlayer vid={videoLinks[overlayPlayerIndex]} />
        </>
      }
      
      <div className='mg-logo-container'>
        {/* <ElementWrapper> */}
          <img src={mgLogo} alt='kiva' />
        {/* </ElementWrapper> */}
      </div>
      

      
      <div className='bg-gradient-shadow' />

      <div className='scrolled-header'>
        <img onClick={() => scrollUp()} src={play_icon} alt="scroll button" />
      </div>

      <div onClick={() => navigate('/')} className='back-btn-container'>
        <HBOBtn label={"BACK"} type={'nofill'}/>
      </div>

      <div className='featured-carousel-container'>
        <div className='carousel-slide-backdrop'>
          <div className='top-black-fold' />
          <div className='bot-black-fold' />
          <div className='video-header'>
            <ElementWrapper>
              <div className='video-title-image'>
                <img src={c1_img} alt="Willy Wonka and the Chocolate Factory title"></img>
              </div>
            </ElementWrapper>
            <ElementWrapper delay={0.3}>
              <div className='video-title'>
                <h2>RECENTLY ADDED</h2>
                <h3>A title sequence for chocolate lovers.</h3>
              </div>
            </ElementWrapper>
            <div className='btns-container'>
              <ElementWrapper delay={0.5}>
                <img onClick={() => setOverlayPlayerIndex('0')} className='play-btn' src={play_icon} alt='play button' />
              </ElementWrapper>
              <ElementWrapper delay={0.6}>
                <HBOBtn label={"MORE INFO"} type={'fill'}/>
              </ElementWrapper>
            </div>
          </div>
          <div className='carousel-video-container'>
            <video autoPlay loop muted playsInline>
              <source src={mainCover} type='video/mp4' />
            </video>
          </div>
        </div>
      </div>

      <div className='categories'>
          <CategoryRow title={"For You"} panelType={'wide'} videoDetails={forYouDetails} updatePlayer={setOverlayPlayerIndex}/>
          <CategoryRow title={"Just Added"} panelType={'tall'} videoDetails={justAddedDetails} updatePlayer={setOverlayPlayerIndex}/>
          <CategoryRow title={"Coming Soon"} panelType={'wide'}/>
      </div>
    </MotionGraphicsContainer>
  )
}
