import { motion } from 'framer-motion';
import { useFadeInUpAnimation } from '../../hooks/animations/fadeUpAndIn';

export default function ElementWrapper({ children, delay=0.2 }) {
    const motionPropsWithDelay = useFadeInUpAnimation(delay);

    return (
        <motion.div {...motionPropsWithDelay}>
            { children }
        </motion.div>
    )
}
