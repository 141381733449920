import { Outlet } from 'react-router'

// styles
import {
  HomeContainer,
  BGGradient,
  HeaderContainer,
  OutletContainer
} from './Home.styles.js'

// components
import Header from './components/header/Header'

export default function Home() {
  return (
    <HomeContainer>
        <BGGradient />
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <OutletContainer>
          <Outlet />
        </OutletContainer>
    </HomeContainer>
  )
}
