// styles
import './HBOBtn.scss'

export default function HBOBtn({ label, type }) {
  return (
    <div className={`hbo-btn-container ${type==='fill' ? "fill" : "nofill"}`}>
        <h3>
            {label}
        </h3>
    </div>
  )
}
