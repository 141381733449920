import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';

// hooks
import { useFadeInUpAnimation } from '../../hooks/animations/fadeUpAndIn.js';

// styles
import {
  WorkContainer,
  WorkPanelsContainer,
  IntroContainer,
  IntroText,
  PanelContainer
} from './Work.styles.js';

// components
import WorkPanel from './components/WorkPanel/WorkPanel';

export default function Work() {
  const navigate = useNavigate()
  const motionPropsWithDelay1 = useFadeInUpAnimation(0.2);
  const motionPropsWithDelay2 = useFadeInUpAnimation(0.4);

  return (
    <WorkContainer>
      <IntroContainer>
        <IntroText>
          <motion.h1 {...motionPropsWithDelay1}>Hi, I'm Kiva.</motion.h1>
          <motion.h4 {...motionPropsWithDelay2}>product designer with a focus on augmented reality and web development</motion.h4>
        </IntroText>
      </IntroContainer>
      <WorkPanelsContainer>
          <PanelContainer id='product-design-wp' onClick={() => navigate('/product-design/mta')}>
            <WorkPanel title={'PRODUCT DESIGN'} url={'product-design'} btnCol={'#e2ac43'}/>
          </PanelContainer>
          <PanelContainer id='motion-graphics-wp' onClick={() => navigate('/motion-graphics')}>
            <WorkPanel title={'MOTION GRAPHICS'} url={'motion-graphics'} btnCol={'#581b55'}/>
          </PanelContainer>
          <PanelContainer id='platform-wp'>
            <a href='https://platfrm.us' target='_blank' rel='noreferrer'>
              <WorkPanel title={'AUGMENTED REALITY'} url={''} btnCol={'#5ca664'}/>
            </a>
          </PanelContainer> 
          <PanelContainer id='presence-wp' onClick={() => navigate('/presence')}>
            <WorkPanel title={'E-COMMERCE SITE (PROCESS BLOG)'} url={'presence'} btnCol={'#581b55'}/>
          </PanelContainer>
      </WorkPanelsContainer>
    </WorkContainer>
  )
}