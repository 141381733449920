// styles
import { OverviewFooterContainer } from './OverviewFooter.styles.js';

export default function OverviewFooter({ children }) {
  return (
    <OverviewFooterContainer>
        {children}
    </OverviewFooterContainer>
  )
}
