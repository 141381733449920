import styled from "styled-components";

export const AboutContainer = styled.div`
    background-color: white;
    height: fit-content;

    @media only screen and (max-width: 400px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 401px) {
        margin-top: 275px;
    }

    @media only screen and (min-width: 669px) {
        margin-top: 200px;
    }
`
export const AboutDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media only screen and (max-width: 668px) {
        margin-left: 30px;
        margin-right: 30px;
    }

    @media only screen and (min-width: 669px) {
        margin-left: 100px;
        margin-right: 100px;
    }
`
export const AboutBio = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;

    a {
        padding-bottom: 20px;
        text-decoration: none;
    }

    h5 {
        font-weight: 400;
        max-width: 780px;
    }
    
    @media only screen and (max-width: 668px) {
        align-items: center;
        margin-top: 20px;
    }
    @media only screen and (min-width: 669px) {
        margin-top: 20px;
    }
`
export const AboutSkills = styled.div`
    background-color: red;
`