import styled from "styled-components";

export const NavBtnContainer = styled.div`
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 100px;

    h4 {
        font-weight: 600;
    }

    &:hover {
        transform: ${props => props.$isActive ? 'none' : 'scale(1.1)'};
        transition: 0.3s ease-in-out;
    }
`