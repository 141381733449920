import styled from "styled-components";
// import { motion } from "framer-motion";

// export const ImgLoaderContainer = styled(motion.img)`
//     height: 100%;
//     width: 100%;
// `

export const ImgLoaderContainer = styled.img`
    height: 100%;
    width: 100%;
`