// styles
import { AppIconContainer } from './AppIcon.styles.js'

export default function AppIcon({ name="und", appIcon }) {
  return (
    <AppIconContainer>
        <img className='app-icon' src={appIcon} alt="" />
      <h4>{ name }</h4>
    </AppIconContainer>
  )
}