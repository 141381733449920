// styles
import { CategoryRowContainer } from './CategoryRow.styles.js';

// components
import ElementWrapper from '../../../../components/element-wrapper/ElementWrapper.js';

export default function CategoryRow({title, panelType, videoDetails=null, updatePlayer}) {
  return (
    <CategoryRowContainer>
        <div className='category-title'>
            <h1>{title}</h1>
        </div>

        <div className='category-panels-container'>
            {videoDetails && videoDetails.map((details, index) => (
              <ElementWrapper delay={0.2 * index}>
                <div key={details.id} onClick={() => updatePlayer(details.id)} style={{ backgroundImage: `url(${details.cover})`, backgroundSize: 'cover' }} className={`${panelType==='wide' ? "category-panel-wide" : "category-panel-tall"}`} />
              </ElementWrapper>
            ))
            }
            {!videoDetails &&
              <>
                <ElementWrapper>
                  <div className={`${panelType==='wide' ? "category-panel-wide" : "category-panel-tall"}`}></div>
                </ElementWrapper>
                <ElementWrapper delay={0.2}>
                  <div className={`${panelType==='wide' ? "category-panel-wide" : "category-panel-tall"}`}></div>
                </ElementWrapper>
                <ElementWrapper delay={0.4}>
                  <div className={`${panelType==='wide' ? "category-panel-wide" : "category-panel-tall"}`}></div>
                </ElementWrapper>
                <ElementWrapper delay={0.6}>
                  <div className={`${panelType==='wide' ? "category-panel-wide" : "category-panel-tall"}`}></div>
                </ElementWrapper>
              </>
            }
        </div>
    </CategoryRowContainer>
  )
}
