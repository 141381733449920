import styled from "styled-components";

export const MotionGraphicsContainer = styled.div`
    @media only screen and (max-width: 1080px) {
        background-image: linear-gradient(90deg, #581B55, #211D5D);
        padding-bottom: 50px;
    
        .overlay-player-btn-container {
            margin-left: 50px;
            margin-top: 50px;
            position: fixed;
            z-index: 2000;
        }
        .bg-gradient-shadow {
            background-image: linear-gradient(135deg, rgba(0,0,0,0.8), rgba(0,0,0,0));
            height: 100vh;
            width: 100vw;
            position: fixed;
        }
        .mg-logo-container {    
            left: 30px;
            position: fixed;
            top: 60px;
            z-index: 100;
    
            img {
                width: 72px;
            }
        }
        .scrolled-header {
            background-image: linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0));
            display: flex;
            height: 200px;
            position: fixed;
            width: 100%;
            z-index: 49;
    
            img {
                cursor: pointer;
                height: 40px;
                right: 0;
                margin-right: 30px;
                margin-top: 50px;
                position: absolute;
                transform: rotate(-90deg);
            }
        }
        .back-btn-container {
            right:30px;
            margin-top: 50px;
            position: absolute;
            z-index: 75;
        }
        .featured-carousel-container {
            position: relative;
            z-index: 50;
    
            .carousel-slide-backdrop {
                background-color: #432C0E;
                display: flex;
                height: 560px;
                position: relative;
    
                .top-black-fold {
                    background-image: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0));
                    bottom: 0;
                    height: 263px;
                    position: absolute;
                    width: 100vw;
                    z-index: 1;
                }
                .bot-black-fold {
                    background-image: linear-gradient(180deg, rgba(0,0,0,1), rgba(0,0,0,0));
                    bottom: -150px;
                    height: 150px;
                    position: absolute;
                    width: 100vw;
                    z-index: 1;
                }
                .video-header {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-left: 30px;
                    margin-top: 300px;
                    z-index: 2;
    
                    .video-title-image {
                        margin-bottom: 0px;
    
                        img {
                            height: 120px;
                        }
                    }    
                    .video-title {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
    
                        h2,h3 {
                            color: #FFFFFF;
                        }
    
                        h2 {
                            font-size: 16px;;
                            font-weight: 700;
                        }
    
                        h3 {
                            font-size: 14px;
                            font-weight: 500;
                        }
    
                    }
    
                    .btns-container {
                        align-items: center;
                        display: flex;
                        gap: 24px;
    
                        img {
                            cursor: pointer;
                            height: 66px;
                        }
                    }
                }
                .carousel-video-container {
                    position: absolute;
                    z-index: inherit;

                    .splash-video-player {
                        pointer-events: none;
                    }
    
                    video {
                        width:100vw;
                        height: 560px;
                        object-fit: cover;
                    }
                }
            }
        }
        .categories {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-left: 30px;
            margin-top: 80px;
            position: relative;
            z-index: 1;
        }
    }
    @media only screen and (min-width: 1080px) {
        background-image: linear-gradient(90deg, #581B55, #211D5D);
        padding-bottom: 50px;

        .overlay-player-btn-container {
            margin-left: 100px;
            margin-top: 50px;
            position: fixed;
            z-index: 2000;
        }

        .bg-gradient-shadow {
            background-image: linear-gradient(135deg, rgba(0,0,0,0.8), rgba(0,0,0,0));
            height: 100vh;
            width: 100vw;
            position: fixed;
        }

        .mg-logo-container {
            left: 50%;
            position: fixed;
            top: 50px;
            transform: translate(-50%);
            z-index: 100;

            img {
                width: 110px;
            }
        }

        .scrolled-header {
            background-image: linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0));
            display: flex;
            height: 200px;
            position: fixed;
            width: 100%;
            z-index: 49;

            img {
                cursor: pointer;
                height: 60px;
                margin-left: 100px;
                margin-top: 50px;
                transform: rotate(-90deg);
            }
        }

        .back-btn-container {
            margin-left: 100px;
            margin-top: 50px;
            position: absolute;
            z-index: 75;
        }

        .featured-carousel-container {
            position: relative;
            z-index: 50;

            .carousel-slide-backdrop {
                background-color: #432C0E;
                display: flex;
                height: 560px;
                position: relative;

                .top-black-fold {
                    background-image: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0));
                    bottom: 0;
                    height: 263px;
                    position: absolute;
                    width: 100vw;
                    z-index: 1;
                }

                .bot-black-fold {
                    background-image: linear-gradient(180deg, rgba(0,0,0,1), rgba(0,0,0,0));
                    bottom: -150px;
                    height: 150px;
                    position: absolute;
                    width: 100vw;
                    z-index: 1;
                }

                .video-header {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-left: 100px;
                    margin-top: 240px;
                    z-index: 2;

                    .video-title-image {
                        margin-bottom: 40px;

                        img {
                            height: 120px;
                        }
                    }
                    
                    .video-title {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        h2,h3 {
                            color: #FFFFFF;
                        }

                        h2 {
                            font-size: 16px;;
                            font-weight: 700;
                        }

                        h3 {
                            font-size: 14px;
                            font-weight: 500;
                        }

                    }

                    .btns-container {
                        align-items: center;
                        display: flex;
                        gap: 24px;

                        img {
                            cursor: pointer;
                            height: 66px;
                        }
                    }
                }

                .carousel-video-container {
                    position: absolute;
                    z-index: inherit;

                    video {
                        width:100vw;
                        height: 560px;
                        object-fit: cover;
                    }
                }
            }
        }

        .categories {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-left: 100px;
            margin-top: 100px;
            position: relative;
            z-index: 1;
        }
    }
`