// styles
import { ProjectOverviewContainer } from './ProjectOverview.styles.js'

// components
import OverviewFooter from '../overview-footer/OverviewFooter.js'
import ProductDesignBtn from '../product-design-btn/ProductDesignBtn.js'

export default function ProjectOverview({ children }) {
    const handleScroll = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
      }

    return (
        <ProjectOverviewContainer>
            {children}
            <OverviewFooter>
                <div onClick={() => handleScroll()}>
                    <ProductDesignBtn text={"Scroll to top"} />
                </div>
            </OverviewFooter>
        </ProjectOverviewContainer>
    )
}
