import styled from "styled-components";

export const HomeContainer = styled.div`
    height: 100vh;
`
export const BGGradient = styled.div`
    background: #2b3b54;
    /* background: radial-gradient(circle, rgba(43,58,84,1) 35%, rgba(35,86,131,1) 100%); */
    height: 100vh;
    position: fixed;
    width: 100vw;
`
export const HeaderContainer = styled.div`
    position: fixed;
    z-index: 100;
`
export const OutletContainer = styled.div`
    position: absolute;
    width: 100vw;
    z-index: 99;
`