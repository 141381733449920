import styled from "styled-components";

export const TextContainer = styled.h3`
    font-size: 18px;
    line-height: 27px;

    @media only screen and (max-width: 375px) {
        font-size: 14px;
        line-height: 18px;
    }

    @media only screen and (max-width: 668px) {
        border-left: 1px solid #464646;
        font-weight: 500;
        padding-left: 20px;
    }
    @media only screen and (min-width: 668px) and (max-width: 968px) {
        border-left: 1px solid #464646;
        font-weight: 500;
        padding-left: 20px;
    }
    @media only screen and (min-width: 968px) {
        border-left: 1px solid #464646;
        font-weight: 500;
        padding-left: 20px;
    }
`