// styles
import { ListContainer } from "./List.styles";

// components
import ElementWrapper from "../../../../../../../../components/element-wrapper/ElementWrapper";

export default function List({ children }) {
  return (
    <ListContainer>
        <ElementWrapper>
            {children}
        </ElementWrapper>
    </ListContainer>
  )
}
