import styled from "styled-components";

export const ProductDesignContainer = styled.div`
    background: rgb(186,13,99);
    background: linear-gradient(45deg, rgba(186,13,99,1), rgba(226,172,67,1) 88% 100%);
    position: relative;
    width: 100vw;
`
export const ProductDesignHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px;
`
export const HeaderLeft = styled.div`
    align-items: center;
    color: #FFFFFF;
    display: flex;
    gap: 12px;

    #clock {
        font-weight: 400;
    }

    #calendar-date {
        font-weight: 400;
    }

    .ios-back-btn {
        align-items: center;
        cursor: pointer;
        display: flex;

        h4 {
            font-size: 20px;
            font-weight: 400;
            padding: 5px 10px;
            }
        img {
            height: 20px;
        }
    }
    @media only screen and (max-width: 375px) {
        #clock {
            display: none;
        }

        #calendar-date {
            display: none;
        }
    }
    @media only screen and (max-width: 468px) {
        #clock {
            display: none;
        }
    }   
`
export const HeaderRight = styled.div`
    align-items: center;
    color: #FFFFFF;
    display: flex;
    gap: 12px;

    h5 {
        font-weight: 600;
    }

    img {
        height: 16px;
    }
`
export const AppsContainer = styled.div`
    align-items: center;
    display: flex;
    overflow: scroll;

    p {
        color: #FFFFFF;
        margin: 0px 30px;
        max-width: 150px;
        min-width: 100px;
        text-align: center;
    }

    @media only screen and (min-width: 668px) {
        p {
            display: none;
        }
    }
`