// assets
import cover from './assets/cover.png';
import logo from './assets/logo_v2_light.png';
import A_092423 from './assets/process-screenshots/092423_A.png'
import B_092423 from './assets/process-screenshots/092423_B.png'
import A_100823 from './assets/process-screenshots/100823_A.png'
import B_100823 from './assets/process-screenshots/100823_B.png'
import C_100823 from './assets/process-screenshots/100823_C.png'
import D_100823 from './assets/process-screenshots/100823_D.png'
import E_100823 from './assets/process-screenshots/100823_E.png'
import F_100823 from './assets/process-screenshots/100823_F.png'
import A_111923 from './assets/process-screenshots/111923_A.png'

// styles
import {
  PresenceContainer,
  BGGradient,
  LogoContainer,
  CoverContainer,
  ButtonsContainer,
  EntriesContainer,
  EntryColumn,
  EntryDualColContainer,
  ProcessEntry,
  YouTubeContainer,
  ComponentContainer
} from './PresenceClothing.styles.js'

// components
import ElementWrapper from '../../components/element-wrapper/ElementWrapper.js';
import ImgLoader from '../../components/img-loader/ImgLoader.js';
import OverviewFooter from '../product-design/components/overview-footer/OverviewFooter.js';
import SecondaryBtn from '../../components/btns/SecondaryBtn/SecondaryBtn.js';

export default function PresenceClothing() {

  const handleScroll = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
  }

  return (
    <PresenceContainer>

      <BGGradient />

      <LogoContainer>
        <ElementWrapper>
          <img src={logo} alt='logo'/>
        </ElementWrapper>
        <ElementWrapper delay={0.8}>
          <h5><em>9/2024 - 12/2024</em></h5>
        </ElementWrapper>
        <ButtonsContainer>
          <ElementWrapper delay={1}>
              <SecondaryBtn label={"BACK"} btnCol={'rgba(255, 255, 255, 0.0)'} borderCol={'#FFFFFF'} url={"/"}/>
          </ElementWrapper>
          <ElementWrapper delay={1.2}>
            <a href='https://remarkable-sopapillas-49a466.netlify.app/' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}>
              <SecondaryBtn label={"VISIT THE SITE"} btnCol={'rgba(219,120,212,0.88)'}/>
            </a>
          </ElementWrapper>
        </ButtonsContainer>
      </LogoContainer>

      <EntriesContainer>
        <EntryDualColContainer>          
          <EntryColumn $width={50}>
            <ElementWrapper> 
              <ProcessEntry>
                <h1 className='entry-header'>PROJECT DESCRIPTION</h1>
                <p>
                  The name of this project is '<b>281,474,976,710,656</b>'. It is part of a collection I am designing
                  titled <em>presence</em>, a curation of experience design work that endorses the practice of being and living in the present moment.
                  <b> 281,474,976,710,656</b> is an e-commerce site with a focus on brand engagement. Through this project I aim to provide
                  unique experiences across multiple brand touchpoints. The financial feasibility of this project remains to be seen, but
                  financial optics are beyond the scope of this project.
                </p>
                <p>
                  One way I am looking to heighten the e-commerce experience is through AR experiences that facilitate engaging
                  interactions with the website and its products. My marketing campaign for this project will involve designing
                  print posters that activate AR experiences. These will allow customers to interact with the brand as well as 
                  try on clothing via social media filters. The final main component is to create website that can process
                  customer transactions.
                </p>
              </ProcessEntry>
            </ElementWrapper>
          </EntryColumn>
            <EntryColumn $width={50}>
              <ElementWrapper> 
                <CoverContainer>
                  <ImgLoader src={cover} alt={"cover image"} />
                </CoverContainer>
              </ElementWrapper>
            </EntryColumn>
        </EntryDualColContainer>

        <ProcessEntry>
          <h1 className='entry-header'>PROJECT COMPONENTS</h1>
          <div className='project-components-container'>
            <ComponentContainer>  
              <ElementWrapper delay={0.3}>
                <div className='project-components-col'>
                  <h3>WEBSITE</h3>
                  <ul>
                    <li>Design:</li>
                    <ul>
                      <li>Create wireframes</li>
                      <li>Design mockups</li>
                      <li>Create prototype</li>
                    </ul>
                    <li>Development:</li>
                    <ul>
                      <li>Code frontend UI in React</li>
                      <li>Integrate Google Firebase to connect database</li>
                      <li>Integrate Stipe API to process transactions</li>
                      <li>Purchase Domain</li>
                    </ul>
                  </ul>
                </div>
              </ElementWrapper>
            </ComponentContainer>
            <ComponentContainer>  
              <ElementWrapper delay={0.6}>
                <div className='project-components-col'>
                  <h3>PRODUCT</h3>
                  <ul>
                    <li>Design products</li>
                    <li>Find supplier</li>
                  </ul>
                </div>
              </ElementWrapper>
            </ComponentContainer>
            <ComponentContainer>
              <ElementWrapper delay={0.9}>
                <div className='project-components-col'>
                  <h3>MARKETING</h3>
                  <ul>
                    <li>Design and print posters</li>
                    <li>Develop AR experience for poster interaction</li>
                    <li>Create Snapchat filter for trying on products</li>
                  </ul>
                </div>
              </ElementWrapper>
            </ComponentContainer>
          </div>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>8-WEEK PROJECT PLAN & DELIVERABLES</h1>
            <ElementWrapper>      
              <h3 className='entry-subheader'>Week 1: Planning, Research & Design</h3>
              <ul>
                <li><b>Project Description</b>: Define project scope and goals</li>
                <li><b>Objective Definition</b>: Define website's core objectives and desired features</li>
                <li><b>Wireframing</b>: Outline website's structure and flows</li>
                <li><b>UI/UX Design</b>: Create high-fidelity mockups, focusing on brand aesthetics and user experience</li>
                <li><b>Physical Product Design</b>: Begin process of designing website products</li>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
              <h3 className='entry-subheader'>Week 2: Begin Front-End Development</h3>
              <ul>
                <li><b>HTML & CSS Structure</b>: Build basic structure for landing, product, and other essential pages</li>
                <li><b>Responsive Design</b>: Cater to various device sizes.</li>
                <li><b>JavaScript Interactivity</b>: Integrate dynamic elements (buttons, microinteractions, animations)</li>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
              <h3 className='entry-subheader'>Week 3: Start Back-End Development</h3>
              <ul>
                <li><b>Database Setup</b>: Establish the structure for products, users, orders, etc.</li>
                <li><b>Core Functionalities</b>: Implement logic for product listings, cart operations, and checkout processes</li>
                <li><b>Server Configuration</b>: Set up the server environment and ensure everything runs smoothly</li>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
              <h3 className='entry-subheader'>Week 4: Integrate Front-End & Back-End</h3>
              <ul>
                <li><b>API Integration</b>: Connect the front-end components with the back-end with Google Firebase (No-SQL)</li>
                <li><b>Functional Testing</b>: Ensure that essential features like the cart, and product listings are working</li>
                <li><b>Feedback Loop</b>: Navigate through the site to spot any UX/UI issues or glitches</li>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
              <h3 className='entry-subheader'>Week 5: Optimization & Advanced Features</h3>
              <ul>
                <li><b>Performance Optimization</b>: Make sure the site loads efficiently, images are optimized, and scripts run smoothly</li>
                <li><b>Security</b>: Ensure that user data is secure, implement SSL for a secure connection</li>
                <li><b>Additional Features</b>: Add any additional features that are missing</li>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
            <h3 className='entry-subheader'>Week 6: Final Testing, Debugging & Deployment</h3>
              <ul>
                <li><b>End-to-End Testing</b>: Go through the entire user journey, from landing on the site to completing a purchase</li>
                <li><b>Bug Fixes & Refinements</b>: Address any issues that arise during testing</li>
                <li><b>Deployment</b>: Transfer the site to a live server, ensure all functionalities remain intact</li>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
              <h3 className='entry-subheader'>Week 7: AR Poster Design & Pre-Launch Activities</h3>
              <ul>
                <li><b>AR Poster Design:</b></li>
                <ul>
                  <li><b>Theme & Concept</b>: Decide on the main themes and messages the posters will convey</li>
                  <li><b>Graphic Design:</b>: Design posters, ensuring they align with brand aesthetics and message</li>
                </ul>
                <li><b>AR Experience Development:</b></li>
                <ul>
                  <li><b>AR Content</b>: Develop AR poster experience with Adobe Aero</li>
                  <li><b>Test AR Activation</b>: Use AR tools to link content to the posters, test activation process to ensure it delivers the intended experience</li>
                </ul>
                <li><b>Pre-Launch Site Review:</b></li>
                <ul>
                  <li><b>Content Review</b>: Ensure all product descriptions, images, and site content are finalized and error-free</li>
                  <li><b>Functionality Test</b>: Conduct a final end-to-end test of the buying process on your website</li>
                </ul>
              </ul>
            </ElementWrapper>
            <ElementWrapper>
            <h3 className='entry-subheader'>Week 8: Launch & Marketing Activation</h3>
              <ul>
                <li><b>Final Preparations:</b></li>
                <ul>
                  <li><b>Feedback Incorporation</b>: Incorporate feedback to make necessary changes</li>
                  <li><b>Backup</b>: Before official launch, backup the site</li>
                </ul>
                <li><b>Official Website Launch:</b></li>
                <ul>
                  <li><b>Go Live</b>: Publish site, ensure all functionalities are running as expected</li>
                  <li><b>Monitor Performance</b>: Track the website's performance, user interactions, and any potential issues</li>
                </ul>
                <li><b>AR Poster Distribution & Promotion:</b></li>
                <ul>
                  <li><b>Poster Distribution</b>: Decide on the distribution channels for posters (e.g., determine physical locations in the city)</li>
                  <li><b>Online Promotion</b>: Use social media and other online platforms to promote the AR experience, reveal poster locations, and drive traffic to the site</li>
                  <li><b>Track Engagement</b>: Monitor how many users interact with the AR posters and their feedback</li>
                </ul>
                <li><b>Ongoing Monitoring & Adjustments:</b></li>
                <ul>
                  <li><b>Feedback Collection</b>: Set up mechanisms to gather customer feedback for both the website and the AR experiences</li>
                  <li><b>Iterative Improvements</b>: As feedback and analytics data come in, make necessary adjustments to enhance user experience</li>
                </ul>
              </ul>
            </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 9/24: WEEK 1</h1>
          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
              <li><b>Project Description</b>: Define project scope and goals</li>
              <ul>
                <li>
                  The scope of the project is to create a fully-functional e-commerce site, as well as to create AR experiences that
                  enhance the customer experience.
                </li>
                <li>
                  A goal is to create a unique experience that focuses on "being present," which I will ideate further upon as the
                  project progresses and branding becomes more solidified.
                </li>
              </ul>
              <li><b>Objective Definition</b>: Define website's core objectives and desired features</li>
              <ul>
                <li>
                  The website will function like a traditional e-commerce store: landing page, product page, cart, checkout. It will 
                  open to a loading page/animation while assets are being retrieved from the database. The home page will open to the 
                  first item of the catalog. The website layout will pan down to view product details, pan up to view cart, and pan
                  left/right to cycle through the catelog. Each product page will have a different gradient background that matches the
                  graphic colors. In the future I wish to add a more
                  3D design that utlizes z-axis space when cycling through products and viewing product details, but for now
                  I want to focus more on getting everything working in a format I'm more familiar with. 
                </li>
                <li>
                  There will be no option to create a user account (at least at this point in time). I am focussed on stripping
                  down traditional online store components and keeping only what is absolutely necessary. User account creation is valuable
                  for user retention, but is not necessary for this site. In the spirit of living in the present, transactions are designed
                  to be done in the moment.
                </li>
              </ul>
              <li><b>Wireframing</b>: Outline website's structure and flows</li>
              <ul>
                <li>
                  (see below)
                </li>
              </ul>
              <li><b>UI/UX Design</b>: Create high-fidelity mockups, focusing on brand aesthetics and user experience</li>
              <ul>
                <li>
                  (see below)
                </li>
              </ul>
              <li><b>Physical Product Design</b>: Begin process of designing website products</li>
              <ul>
                <li>
                  Designed 1 graphic, see image in Week 2
                </li>
              </ul>
            </ul>
          </ElementWrapper>
          <ElementWrapper>
            <EntryDualColContainer>
              <EntryColumn $width={50}>
                <ImgLoader src={A_100823} alt='A_100823'/>
              </EntryColumn>
              <EntryColumn $width={50}>
                <ImgLoader src={B_100823} alt='B_100823'/>
              </EntryColumn>
            </EntryDualColContainer>
            <EntryDualColContainer>
              <EntryColumn $width={50}>
                <ImgLoader src={C_100823} alt='C_100823'/>
              </EntryColumn>
              <EntryColumn $width={50}>
                <ImgLoader src={D_100823} alt='D_100823'/>
              </EntryColumn>
            </EntryDualColContainer>
            <EntryDualColContainer>
              <EntryColumn $width={50}>
                <ImgLoader src={E_100823} alt='E_100823'/>
              </EntryColumn>
              <EntryColumn $width={50}>
                <ImgLoader src={F_100823} alt='F_100823'/>
              </EntryColumn>
            </EntryDualColContainer>
          </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 10/1: WEEK 2</h1>

          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
              <li><b>HTML & CSS Structure</b>: Build basic structure for landing, product, and other essential pages</li>
                <ul>
                  <li>
                    Much of the structure is up, what is left is the structure for the cart design and adding the individual product page animations (will be done at a later date when the products are all designed)
                  </li>
                </ul>
              <li><b>Responsive Design</b>: Cater to various device sizes.</li>
                <ul>
                  <li>
                    In progress
                  </li>
                </ul>
              <li><b>JavaScript Interactivity</b>: Integrate dynamic elements (buttons, microinteractions, animations)</li>
                <ul>
                  <li>
                    Core JavaScript is complete, just need to add a React Context for products. This will allow pages to access product data (stored in a local JSON file at the moment, will transfer to Firebase as part of next week's deliverables)
                  </li>
                </ul>
            </ul>
          </ElementWrapper>
          <ElementWrapper>
            <EntryDualColContainer>
              <EntryColumn $width={50}>
                <ImgLoader src={A_092423} alt='A_092423' />
              </EntryColumn>
              <EntryColumn $width={50}>
                <ImgLoader src={B_092423} alt='B_092423' />
              </EntryColumn>
            </EntryDualColContainer>
          </ElementWrapper>
          <ElementWrapper>
            <h3 className='entry-subheader'>Next Steps</h3>
            <ul>
              <li>
                Going to be coding for most of October, focused on completing the website front-end before the end of the month.
              </li>
              <li>
                The rest of the product pages will have the same layout.
              </li>
              <li>
                Still need to add responsive design for the pages I've developed thus far.
              </li>
            </ul>
          </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 10/8: WEEK 3</h1>

          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
              <li><b>Database Setup</b>: Establish the structure for products, users, orders, etc.</li>
                <ul>
                  <li>
                    The orders will be handled by the Stripe API, so no need to configure architecture. Product information is stored
                    in Google Firestore, categorized by product with all relevant information needed to load assets, details, and to
                    dynamically change the UI.
                  </li>
                </ul>
              <li><b>Core Functionalities</b>: Implement logic for product listings, cart operations, and checkout processes</li>
                <ul>
                  <li>
                    Achieved through JavaScript, will attach the Stripe API calls at a later date.
                  </li>
                </ul>
              <li><b>Server Configuration</b>: Set up the server environment and ensure everything runs smoothly</li>
              <ul>
                <li>
                  I am running the server locally for now. In the coming weeks I will link my Github to Netlify to host a live site.
                </li>
              </ul>
            </ul>
          </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 10/15: WEEK 4</h1>

          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
                <li>
                  <b>API Integration</b>: Connect the front-end components with the back-end with Google Firebase (No-SQL)
                  <ul>
                    <li>Firestore No-SQL database has been linked to support product catalogue data.</li>
                  </ul>
                </li>
                <li><b>Functional Testing</b>: Ensure that essential features like the cart, and product listings are working</li>
                  <ul>
                    <li>Cart functionality has been added and product pages display correctly for the current stage of development</li>
                  </ul>
                <li><b>Feedback Loop</b>: Navigate through the site to spot any UX/UI issues or glitches</li>
                  <ul>
                    <li>No UI/UX glitches surfacing so far. There is the issue transitions/transition animations, but those will be added once all site functionality is added.</li>
                  </ul>
              </ul>
          </ElementWrapper>

          <ElementWrapper>
            <ProcessEntry>
              <h3 className='entry-subheader'>Video Update</h3>
              <YouTubeContainer>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/kzA6wPI_Uvs?si=kySA0fUj7LIDuH_j" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </YouTubeContainer>
            </ProcessEntry>
          </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 10/22: WEEK 5</h1>

          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
              <li><b>Performance Optimization</b>: Make sure the site loads efficiently, images are optimized, and scripts run smoothly</li>
                <ul>
                  <li>Images are not optimizmed yet</li>
                  <li>The site utilizes redux-persistor/reselect to minimize re-rendering</li>
                  <li>Data is fetched on the load screen, which ultimately reduced database reads</li>
                  <li>Small bugs in checkout (cart not cleared, size buttons staying active)</li>
                </ul>
              <li><b>Security</b>: Ensure that user data is secure, implement SSL for a secure connection</li>
                <ul>
                  <li>The domain, when purchased, will have secure connection as is required by Stripe</li>
                </ul>
              <li><b>Additional Features</b>: Add any additional features that are missing</li>
                <ul>
                  <li>Beginning work on individual product pages, mainly the one product that I have finalized.</li>
                  <li>Slightly worried about building up a catalogue, but shifting more into that mindset now that a presentable version of the website checkout is complete.</li>
                  <ul>
                    <li>Will likely reduce number from 8 to 3-4 "more solid" products, as I will also be needing to create social media filters/AR experiences for each</li>
                  </ul>
                </ul>
            </ul>
          </ElementWrapper>
          <ElementWrapper>
            <ProcessEntry>
              <h3 className='entry-subheader'>Video Update</h3>
              <YouTubeContainer>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/cC86QvI5sl8?si=4UM4jC5PUZihqPUI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </YouTubeContainer>
            </ProcessEntry>
          </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 10/29: WEEK 6</h1>
          <ElementWrapper>
            <a href='https://remarkable-sopapillas-49a466.netlify.app/' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}>
              <div>
                <SecondaryBtn label={"VISIT THE SITE"} btnCol={'rgba(255, 96, 186, 0.7)'}/>
              </div>
            </a>
          </ElementWrapper>
          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
                <li><b>End-to-End Testing</b>: Go through the entire user journey, from landing on the site to completing a purchase</li>
                  <ul>
                    <li>User journey appears stable/presentable. Small issue with loading: to reduce database fetches I load everything when the landing page mounts. This works fine, as the data will not change once the site goes live, but if the user loads any page other than the loading page first, and data is not cached to the browser, there is a case where there is no data to display. Will fix eventually with a flag to redirect to the landing page is assets are missing, but not important for now.</li>
                  </ul>
                <li><b>Bug Fixes & Refinements</b>: Address any issues that arise during testing</li>
                  <ul>
                    <li>Still need to complete responsive design, but not pressing. Most importantly will need to design for 55" display for showxase more than anything.</li>
                  </ul>
                <li><b>Deployment</b>: Transfer the site to a live server, ensure all functionalities remain intact</li>
                  <ul>
                    <li>Site is being hosted on Netlify with assigned domain until personal domain is purchased.</li>
                  </ul>
              </ul>
          </ElementWrapper>
          <ElementWrapper>
            <ul>
              <li>Happy with this week's work, database is working smoothly in retrieving product catalogue data. A quick note on branding, this is no longer a clothing company but an experience company. I'm very inspired by <a href="https://mschf.com/" target='_blank' rel="noreferrer">MSCHF</a>, a design collective with a diverse range of products. I want to emulate something similar and not be tied to a specific industry other than experience, which has unlimited applications.</li>
              <li>The most obvious issue is the lack of cart persistancy on a page reload, which hasn't been added in yet.</li>
              <li>This next week will be mainly optimizing the quasi-functional site, specifically integrating Redux and optimizing application rendering/re-rendering. I'll also optimize images for faster loading.</li>
            </ul>
          </ElementWrapper>
          <ElementWrapper>
            <ProcessEntry> 
              <h3 className='entry-subheader'>Video Update</h3>
              <YouTubeContainer>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BnEqoseGYrg?si=-Df978jh4XmoGg4P" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </YouTubeContainer>
            </ProcessEntry>
          </ElementWrapper>
        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 11/12: WEEK 7</h1>
          <ElementWrapper>
            <a href='https://remarkable-sopapillas-49a466.netlify.app/' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}>
              <div>
                <SecondaryBtn label={"VISIT THE SITE"} btnCol={'rgba(255, 96, 186, 0.7)'}/>
              </div>
            </a>
          </ElementWrapper>
          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
              <li><b>AR Poster Design:</b></li>
              <ul>
                <li>General poster design provided below</li>
              </ul>
              <li><b>AR Experience Development:</b></li>
              <ul>
                <li>Still working on this, general sketches complete for the AR experience design</li>
              </ul>
              <li><b>Pre-Launch Site Review:</b></li>
              <ul>
                <li>Moving this to the backburner. Website is showcase-presentable, working on responsive design in the background of marketing tasks</li>
              </ul>
            </ul>
          </ElementWrapper>

          <ElementWrapper>
            <EntryDualColContainer>
              <EntryColumn $width={33}>
                <ImgLoader src={A_111923} alt='A_111923' />
              </EntryColumn>
            </EntryDualColContainer>
          </ElementWrapper>

        </ProcessEntry>

        <ProcessEntry>
          <h1 className='entry-header'>SUN 11/19: WEEK 8</h1>
          <ElementWrapper>
            <a href='https://remarkable-sopapillas-49a466.netlify.app/' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}>
              <div>
                <SecondaryBtn label={"VISIT THE SITE"} btnCol={'rgba(255, 96, 186, 0.7)'}/>
              </div>
            </a>
          </ElementWrapper>
          <ElementWrapper>
            <h3 className='entry-subheader'>Deliverables</h3>
            <ul>
              <li><b>Final Preparations:</b></li>
              <ul>
                <li><b>Feedback Incorporation</b>: Incorporate feedback to make necessary changes.</li>
                <li><b>Backup</b>: Before official launch, backup the site.</li>
              </ul>
              <li><b>Official Website Launch:</b></li>
              <ul>
                <li><b>Go Live</b>: Publish site, ensure all functionalities are running as expected.</li>
                <li><b>Monitor Performance</b>: Track the website's performance, user interactions, and any potential issues.</li>
              </ul>
              <li><b>AR Poster Distribution & Promotion:</b></li>
              <ul>
                <li><b>Poster Distribution</b>: Decide on the distribution channels for posters (e.g., determine physical locations in the city, consider branding inserts for orders).</li>
                <li><b>Online Promotion</b>: Use social media and other online platforms to promote the AR experience, reveal poster locations, and drive traffic to your site.</li>
                <li><b>Track Engagement</b>: Monitor how many users interact with the AR posters and their feedback.</li>
              </ul>
              <li><b>Ongoing Monitoring & Adjustments:</b></li>
              <ul>
                <li><b>Feedback Collection</b>: Set up mechanisms to gather customer feedback on both the website and the AR experiences.</li>
                <li><b>Iterative Improvements</b>: As feedback and analytics data come in, make necessary adjustments to enhance user experience.</li>
              </ul>
            </ul>
          </ElementWrapper>
        </ProcessEntry>
      </EntriesContainer>
      <ElementWrapper>
        <div style={{ margin: "50px" }}>
          <OverviewFooter>
              <div onClick={() => handleScroll()}>
                <SecondaryBtn label={"SCROLL TO TOP"} btnCol={'rgba(255, 96, 186, 0.7)'}/>
              </div>
          </OverviewFooter>
        </div>

      </ElementWrapper>
    </PresenceContainer>
  )
}
