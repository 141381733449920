import styled from "styled-components";

export const SectionContainer = styled.div`
    @media only screen and (max-width: 668px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    @media only screen and (min-width: 668px) and (max-width: 968px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    @media only screen and (min-width: 968px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`
export const SectionTitle = styled.h2`
    font-weight: 500;

    @media only screen and (max-width: 375px) {
        font-size: 24px;
    }
`
export const ImgContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 40px;

    @media only screen and (max-width: 375px) {
        gap: 29px;
    }
`