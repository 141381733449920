import { SectionContainer, SectionTitle } from "./OverviewSection.styles";

export default function OverviewSection({ sectionNumber, title, children }) {
  return (
    <SectionContainer>
        {sectionNumber &&
          <SectionTitle>{sectionNumber} / <b>{title}</b></SectionTitle>
        }
        { children }
    </SectionContainer>
  )
}