import styled from "styled-components";

export const WorkContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`

export const IntroContainer = styled.div`
    align-items: center;
    display: flex;
    position: fixed;
    top: 300px;
    width: 100vw;

    @media only screen and (max-width: 399px) {
        top: 260px;
    }

    @media only screen and (max-width: 400px) {
        top: 280px;
    }

    @media only screen and (max-width: 669px) {
        justify-content: center;
        
    }

    @media only screen and (min-width: 669px) {
        align-content: start;
        
    }
`
export const IntroText = styled.div`
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    line-height: 24px;
    margin: 0px 100px;

    @media only screen and (min-width: 668px) {
        align-items: start;
        max-width: 375px;
        
    }

    h1 {
        font-size: 30px;

        @media only screen and (min-width: 668px) {
            font-size: 36px;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 500;

        @media only screen and (max-width: 668px) {
            text-align: center;
        }

        @media only screen and (min-width: 668px) {
            font-size: 18px;
        }
    }
`

export const WorkPanelsContainer = styled.div`
    background-color: #2b3b54;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: auto;
    top: 500px;
    margin-left: 100px;
    margin-right: 100px;
    padding-bottom: 50px;
    width: 100%;
    position: relative;

    @media only screen and (max-width: 668px) {
        margin-left: 10px;
        margin-right: 10px;
        top: 450px;
    }
` 
export const PanelContainer = styled.div`
    gap: 20px;
    height: 300px;
    position: relative;
    flex: 0 0 calc(33.333% - 13.333px);

    a {
        text-decoration: none;
        color: #2b3b54;
    }

    @media only screen and (max-width: 1000px) {
        flex: 0 0 calc(50% - 10px);
        height: 200px;
    }
`