// assets
import design from './assets/design.png'
import customizationGIF from './assets/customization.gif';
import logo from './assets/presence-logo.png';
import cover from './assets/cover.png';
import cover2 from './assets/cover2.png';


// components
import OverviewSection from '../../components/overview-section/OverviewSection.js';
import OverviewHeader from '../../components/overview-header/OverviewHeader.js';
import { Heading, TextWithHeading } from '../../components/overview-section/components/section-text/SectionText.styles.js';
import Text from '../../components/overview-section/components/section-text/components/text/Text.js';
import List from '../../components/overview-section/components/section-text/components/list/List.js';
import SectionText from '../../components/overview-section/components/section-text/SectionText.js';
import ImgHolder from '../../components/overview-section/components/img-holder/ImgHolder.js';
import ProjectOverview from '../../components/project-overview/ProjectOverview.js';
import { ImgContainer } from '../../components/overview-section/OverviewSection.styles.js'


export default function PresenceOverview() {
  return (
    <ProjectOverview>
      <OverviewHeader logo={logo} cover={cover} title={'E-Commerce Site'} description={"This is an e-commerce website with a focus on the design of choice. The title of the sole product is '281,474,976,710,656', which represents the total number of possible customizations for a graphic tee."} />

      <OverviewSection>
        <ImgHolder src={cover2} alt={"app mockups"} width={100}/>
      </OverviewSection>
      

      <OverviewSection sectionNumber={"01"} title={"ROLE"}>
        <TextWithHeading>
          <Heading>UI/UX Designer</Heading>
          <List>
            <ul>
              <li>Designed wireframes, mockups and prototype in Figma</li>
            </ul>
          </List>
        </TextWithHeading>
        <TextWithHeading>
          <Heading>Web Developer</Heading>
          <List>
            <ul>
              <li>Coded front-end UI with React</li>
              <li>Integrated Google Firebase Firestore to add database functionality</li>
              <li>Integrated Stripe API to handle transaction requests</li>
            </ul>
          </List>
        </TextWithHeading>
        <TextWithHeading>
          <Heading>Augmented Reality Experience Developer</Heading>
          <List>
            <ul>
              <li>Created AR poster experience with Adobe Aero</li>
              <li>Created Snapchat filter in Lens Studio for trying on t-shirt</li>
            </ul>
          </List>
        </TextWithHeading>
        <TextWithHeading>
          <Heading>Graphic Designer</Heading>
          <List>
            <ul>
              <li>Created posters prints for products</li>
            </ul>
          </List>
        </TextWithHeading>
      </OverviewSection>

      <OverviewSection sectionNumber={"02"} title={"GOALS"}>
        <List>
          <ul>
            <li>Design and develop a fully-functional e-commerce store</li>
            <li>Receive an order</li>
          </ul>
        </List>
      </OverviewSection>

      <OverviewSection sectionNumber={"03"} title={"DESIGN STAGES"}>
        <ImgContainer>
          <ImgHolder src={design} alt={"project wireframes and mockups"} width={100} caption={"Figma workspace"} />
        </ImgContainer>
      </OverviewSection>

      <OverviewSection sectionNumber={"04"} title={"OVERVIEW"}>
        <TextWithHeading>
          <Heading>Customization</Heading>
          <Text>
            The central component is the Create Custom feature, which randomly generates the colors of the t-shirt graphic. Site visitors 
            can pause the randomization generator to capture a color they'd like to purchase.
          </Text>
        </TextWithHeading>
        <ImgHolder src={customizationGIF} alt={"favorite team"} width={75} caption={"281,474,976,710,656 possibilities...find one that feels right."} />
      </OverviewSection>

      <OverviewSection sectionNumber={"05"} title={"RESEARCH"}>
        <SectionText>
          <Text>
            As this project comes to a close, I had the opportuninty to conduct usablity testing on campus. Feedback was generally positive,
            but an area of concern was the Cart button visibility. Typically the cart is located at the top right of the page, where the 
            grid-view button is currently located for this site. 
          </Text>
          <Text>
            I will be removing the 3 preset t-shirt gradients for the final ship of the webiste, so the grid-view button can be removed
            and replaced with a cart button.
          </Text>
        </SectionText>
      </OverviewSection>
      
      <OverviewSection sectionNumber={"5"} title={"NEXT STEPS"}>
        <SectionText>
          <Text>
            I am aiming to officially lauch this site by the end of this month! For an in-depth look into my process behind this project, please visit the <a href='/presence'>Presence Process blog</a>.
          </Text>
        </SectionText>

      </OverviewSection>
    </ProjectOverview>
  )
}
