import styled from "styled-components";

export const ImgHolderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;

    img {
        width: ${props => props.$width}%;
    }

    @media only screen and (max-width: 568px) {
        gap: 14px;

        img {
            width: 100%;
        }
    }
`
export const Caption = styled.h4`
    font-weight: 300;
    font-size: 16px;

    @media only screen and (max-width: 375px) {
        font-size: 10px;
    }
`