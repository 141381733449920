import React from 'react'
import { SectionTextContainer } from './SectionText.styles'

export default function SectionText({ children }) {
  return (
      <SectionTextContainer>
          { children }
      </SectionTextContainer>
  )
}
