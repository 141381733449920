import { NavLink, useLocation, useNavigate } from 'react-router-dom';

// components
import NavBtn from '../../../../components/btns/NavBtn/NavBtn';
import SocialLinks from '../../../../components/social-links/SocialLinks';

// styles
import { HeaderContainer, NavContainer, LinksContainer } from './Header.styles.js';


export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const dotClass = location.pathname === '/about' ? 'about' : 'work';

  return (
    <HeaderContainer>
        <div onClick={() => navigate('/')} className='logo-container'>
            <h2>KIVA LOOR</h2>
        </div>
        <NavContainer>
          <div className='nav-sections'>
            <div className={`dot ${dotClass}`}/>
            <NavLink exact="true" to='/'
              style={
                ({ isActive }) => ({
                opacity: isActive ? '1.0' : '0.4',
                textDecoration: 'none'
                })
              }
            >
              {({ isActive }) => (
                <NavBtn isActive={isActive} label={'WORK'} />
              )}

            </NavLink>
            <NavLink exact="true" to='about'
              style={({ isActive }) => ({
                opacity: isActive ? '1.0' : '0.4',
                textDecoration: 'none'
              })}
            >
              {({ isActive }) => (
                <NavBtn isActive={isActive} label={'ABOUT'} />
              )}
            </NavLink>
          </div>
          <LinksContainer>
            <SocialLinks mode='dark'/>
          </LinksContainer>
          
        </NavContainer>
    </HeaderContainer>
  )
}