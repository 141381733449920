// styles
import './ContactPanel.scss'

export default function ContactPanel({ title }) {
  return (
    <div className='contact-panel-container'>
        {title}
    </div>
  )
}
