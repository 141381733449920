import styled from "styled-components";

export const AppIconContainer = styled.div`
    @media only screen {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 0px;
        margin-bottom: 60px;

        .app-icon {
            background-color: #9f9f9f;
            border-radius: 19px;
            height: 80px;
            margin-bottom: 24px;
            margin-left: 60px;
            margin-right: 60px;
            margin-top: 60px;
            width: 80px;
        }
      
        h4 {
            color: #FFFFFF;
        }
    }
`
