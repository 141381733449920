// styles
import './OverlayPlayer.scss'

export default function OverlayPlayer({ vid }) {
  return (
    <div className='overlay-player-container'>
      <div className='overlay-player-video-container'>
        <iframe className='video' height='100%' width='100%' src={`${vid}?autoplay=1`} title="YouTube video player" allowFullScreen></iframe>
      </div>   
    </div>
  )
}
