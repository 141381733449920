// styles
import './ProductDesignBtn.scss'

export default function ProductDesignBtn({ text }) {
  return (
    <div className='product-design-btn-container'>
        <p>{ text }</p>
    </div>
  )
}
