import { useNavigate } from 'react-router';

// styles
import { NavBtnContainer } from './NavBtn.styles.js';

export default function NavBtn({ isActive, label, url=null }) {
  const navigate = useNavigate()

  const handleNavigate = () => {
    if (url) {
      navigate(`${url}`)
    } else {
      return
    }
  }

  return (
    <NavBtnContainer $isActive={isActive} onClick={() => handleNavigate()}>
      <h4>{label}</h4>
    </NavBtnContainer>
  )
}
