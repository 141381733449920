// styles
import { WorkPanelContainer, Title } from './WorkPanel.styles.js';

export default function WorkPanel({ title }) {
  return (
    <WorkPanelContainer>
        <Title>{title}</Title>
    </WorkPanelContainer>
  )
}
