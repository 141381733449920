import styled from "styled-components";

export const ListContainer = styled.div`
    border-left: 1px solid #464646;

    h3 {
        border: none;
        font-weight: 600;
    }

    ul {
        margin: 0;
    }

    li {
        font-size: 18px;
        line-height: 27px;

        @media only screen and (max-width: 375px) {
            font-size: 14px;
            line-height: 18px;
        }
    }
`