// assets
import pullvaultsketches from './assets/pull-vault-sketches.png'
import pullvaultwireframes from './assets/pull-vault-wireframes.png'
import pullvaultmockups from './assets/pull-vault-mockups.png'
import ao_favoriteTeam from './assets/favorite-team.png'
import ao_teamGradients from './assets/team-gradients.png'
import ao_addCard from './assets/add-card.png'
import ao_cardTypes from './assets/card-types.png'
import ao_setShowcase from './assets/set-showcase.png'
import logo from './assets/pull-vault-logo.png';
import cover from './assets/cover.png';
import cover2 from './assets/cover2.png';


// components
import OverviewSection from '../../components/overview-section/OverviewSection.js';
import ElementWrapper from '../../../../components/element-wrapper/ElementWrapper.js';
import OverviewHeader from '../../components/overview-header/OverviewHeader.js';
import SectionText from '../../components/overview-section/components/section-text/SectionText.js';
import { Heading, TextWithHeading } from '../../components/overview-section/components/section-text/SectionText.styles.js';
import Text from '../../components/overview-section/components/section-text/components/text/Text.js';
import List from '../../components/overview-section/components/section-text/components/list/List.js';
import ImgHolder from '../../components/overview-section/components/img-holder/ImgHolder.js';
import ProjectOverview from '../../components/project-overview/ProjectOverview.js';
import { ImgContainer } from '../../components/overview-section/OverviewSection.styles.js'


export default function PullVaultOverview() {
  return (
    <ProjectOverview>
      <OverviewHeader logo={logo} cover={cover} title={'Pull Vault Tracker'} description={"Pull Vault is a centralized database for tracking trading card collections. The app enhances the collection process through social features that allow collectors to showcase their cards online and list cards for sale."}/>

      <ImgHolder src={cover2} alt={"app mockups"} width={100}/>

      <OverviewSection sectionNumber={"01"} title={"ROLE"}>
        <ElementWrapper>
          <TextWithHeading>
            <Heading>UI/UX Designer</Heading>
            <List>
              <ul>
                <li>Designed wireframes, mockups and prototypes in Adobe XD</li>
              </ul>
            </List>
          </TextWithHeading>
        </ElementWrapper>
        <ElementWrapper>     
          <TextWithHeading>
            <Heading>Web Developer</Heading>
            <List>
              <ul>
                <li>Coded front-end UI with React</li>
                <li>Integrated Google Firebase Authentication to add account creation functionality</li>
                <li>Integrated Google Firebase Firestore to add database functionality</li>
              </ul>
            </List>
          </TextWithHeading>
        </ElementWrapper>
        <ElementWrapper>   
          <TextWithHeading>
            <Heading>Content Creator</Heading>
            <List>
              <ul>
                <li>Created social media content with After Effects</li>
                <li>Managed social media accounts (Instagram, TikTok)</li>
              </ul>
            </List>
          </TextWithHeading>
        </ElementWrapper>
      </OverviewSection>

      <OverviewSection sectionNumber={"02"} title={"GOALS"}>
        <ElementWrapper> 
          <List>
            <ul>
              <li>Design and build an app that helps card collectors track and share their card collections online with ease.</li>
              <li>Log my personal card collection into the app.</li>
            </ul>
          </List>
        </ElementWrapper>
      </OverviewSection>

      <OverviewSection sectionNumber={"03"} title={"DESIGN STAGES"}>
        <ElementWrapper>
          <ImgContainer>
            <ImgHolder src={pullvaultsketches} alt={"Pull Vault sketches"} width={100} caption={"Initial ideation led to a panel-design, meant to represent the modular nature of card collections (i.e. series, sets, collections). Originally I had planned to display a photo of every card in a set, but that would ultimately lead to slower load times and a less efficient user experience. I compromised by adding a Showcase feature, where users can upload photos of their favorite cards and display them that way."} />
          </ImgContainer>
        </ElementWrapper>
        <ElementWrapper>
          <ImgContainer>
            <ImgHolder src={pullvaultwireframes} alt={"Pull Vault wireframes"} width={100} caption={"Wireframes"} />
            <ImgHolder src={pullvaultmockups} alt={"Pull Vault mockups"} width={100} caption={"The color palette for the interface corresponds to the colors of the user's favorite team."} />
          </ImgContainer>
        </ElementWrapper>
      </OverviewSection>

      <OverviewSection sectionNumber={"04"} title={"OVERVIEW"}>
        <ElementWrapper>
          <TextWithHeading>
            <Heading>Personalization</Heading>
            <Text>
              Card collecting can be a very personal activity: collectors amass their favorite players,
              players on their favorite teams, and so on. With this in mind I wanted to include personalization
              in the app, and included a setting that dynamically changes the app's UI to match the colors
              of a chosen favorite team.
            </Text>
          </TextWithHeading>
        </ElementWrapper>
        <ElementWrapper>
          <ImgContainer>
            <ImgHolder src={ao_favoriteTeam} alt={"favorite team"} width={50} caption={"Choose a favorite team in Account settings"} />
            <ImgHolder src={ao_teamGradients} alt={"team gradients"} width={50} caption={"Team-themed UI palettes"} />
          </ImgContainer>
        </ElementWrapper>
        <ElementWrapper>
          <TextWithHeading>
            <Heading>Record Every Card Type</Heading>
            <Text>
              Card sets include a variety of card types (e.g. autographs, relics, variants, etc.). Each
              checklist for Pull Vault includes all of these card variations, helping collectors record their
              collection with accuracy.
            </Text>
          </TextWithHeading>
        </ElementWrapper>
        <ElementWrapper>
          <ImgContainer>
            <ImgHolder src={ao_cardTypes} alt={"card types"} width={50} caption={"Card types"} />
            <ImgHolder src={ao_addCard} alt={"Add new cards"} width={50} caption={"Card variants"} />
          </ImgContainer>
        </ElementWrapper>
        <ElementWrapper>
          <ImgContainer>
            <ImgHolder src={ao_setShowcase} alt={"set showcase"} width={100} caption={"Showcased cards"} />
            <TextWithHeading>
              <Heading>Showcasing</Heading>
              <Text>
                Why collect cards if you can't show them off? The Showcase feature allows collectors to display their favorite cards for
                everyone to see.
              </Text>
            </TextWithHeading>
          </ImgContainer>
        </ElementWrapper>
      </OverviewSection>
      
      <OverviewSection sectionNumber={"5"} title={"NEXT STEPS"}>
        <ElementWrapper>
          <SectionText>
            <Text>
              Amidst job applications I am working on developing this app with React. Once an MVP prototype is built, I am going to begin
              alpha testing with a group of collectors who have shown an early interest in the app. Integrating Google Analytics
              for this stage of testing will provide insight for what changes should be made for beta testing with a larger group.
            </Text>
            <Text>
              Procuring checklist data is a manually intensive process that I steamlined with Python functions that parse through company-issued
              checklists. The data is reformatted into JSON objects that can be fed into the database. I am working on creating these
              checklists in parallel with the website, and am doing checklists for Topps baseball to start. I am also
              exploring AI solutions to speed up this process further.
            </Text>
            <Text>
              If you are a collector interested in early access to this product, feel
              free to reach out on Instagram (<a target='_blank' rel='noopener noreferrer' href="https://www.instagram.com/pull.vault/">@pull.vault</a>).
            </Text>
          </SectionText>
        </ElementWrapper>
      </OverviewSection>
    </ProjectOverview>
  )
}
