// styles
import { SocialLinksContainer } from './SocialLinks.styles.js';

export default function SocialLinks() {
  
  return (
    <SocialLinksContainer>
      <a href="https://www.instagram.com/kivaloor.xd/" target="_blank" rel="noopener noreferrer">
          <div id="instagram-social-link" />
      </a>
      
      <a href="https://www.linkedin.com/in/kivaloor/" target='_blank' rel='noopener noreferrer'>
        <div id="linkedin-social-link" />
      </a>          
  </SocialLinksContainer>
  )
}