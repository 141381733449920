// styles
import {
    HeaderContainer,
    HeaderCol,
    ProjectDescription,
    ProjectDetailsContainer,
    DetailsTitle,
    CoverImg,
    LogoContainer 
} from './OverviewHeader.styles';

// components
import ElementWrapper from '../../../../components/element-wrapper/ElementWrapper';
import ImgLoader from '../../../../components/img-loader/ImgLoader';

export default function OverviewHeader({ logo, cover, title, description }) {

  return (
    <HeaderContainer>
      <HeaderCol>
        <ProjectDetailsContainer>
          <DetailsTitle>
            <ElementWrapper delay={0.2}>
              <LogoContainer>
                <img src={logo} alt={"project logo"}/>
              </LogoContainer>
            </ElementWrapper>
            <ElementWrapper delay={0.3}>
              <h1>{title}</h1>
            </ElementWrapper>
          </DetailsTitle>
          <ElementWrapper delay={0.3}>
            <ProjectDescription>
                {description}
            </ProjectDescription>
          </ElementWrapper>
        </ProjectDetailsContainer>
      </HeaderCol>
      <HeaderCol>
        <CoverImg>
          <ElementWrapper>
            <ImgLoader src={cover} alt="App mockup" />
          </ElementWrapper>
        </CoverImg>
      </HeaderCol>
    </HeaderContainer>
  )
}
