// styles
import { ImgHolderContainer, Caption } from './ImgHolder.styles'

// components
import ElementWrapper from '../../../../../../components/element-wrapper/ElementWrapper';
import ImgLoader from '../../../../../../components/img-loader/ImgLoader';

export default function ImgHolder({ src, alt, width, caption }) {
  return (
    <ImgHolderContainer $width={width}>
        <ElementWrapper>
          <ImgLoader src={src} alt={alt} />
        </ElementWrapper>
        <ElementWrapper>
          {caption &&
            <Caption>{caption}</Caption>
          }
        </ElementWrapper>     
    </ImgHolderContainer>
  )
}
