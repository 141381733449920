// styles
import { ImgLoaderContainer } from './ImgLoader.styles.js';

export default function ImgLoader({ src, alt }) {
  // const controlDiv = useAnimation();

  // useEffect(() => {
  //   controlDiv.set({ scale: 0.9, opacity: 0, zIndex: -1 });
  //   controlDiv.start({
  //     scale: 1,
  //     opacity: 1,
  //     translateZ:-50,
  //     transition: { duration: 1.0, type: 'spring' },
  //   });
  // }, [controlDiv]);

  return (
    <ImgLoaderContainer
      // initial={{ scale: 0.50, translateZ: 100, opacity: 0 }}
      // animate={controlDiv}
      src={src}
      alt={alt}
    />
  )
}