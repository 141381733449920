import ElementWrapper from '../../../../../../../../components/element-wrapper/ElementWrapper';
import { TextContainer } from './Text.styles';

export default function Text({ children }) {
  return (
    <TextContainer>
        <ElementWrapper>
            { children }
        </ElementWrapper>
    </TextContainer>
  )
}
