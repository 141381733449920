import { useNavigate } from 'react-router';

// styles
import { SecondaryBtnContainer } from './SecondaryBtn.styles.js';

export default function SecondaryBtn({ label, url=null, btnCol="#2b3b54", borderCol=null }) {
  const navigate = useNavigate()

  const handleNavigate = () => {
    if (url) {
      navigate(`${url}`)
      console.log(url)
    } else {
      return
    }
  }

  return (
    <SecondaryBtnContainer $bgCol={btnCol} $borderCol={borderCol} onClick={() => handleNavigate()}>
      <h4>{label}</h4>
    </SecondaryBtnContainer>
  )
}
