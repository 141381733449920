import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';

// assets
import battery from './assets/battery-icon.png';
import chevron from './assets/chevron-icon.png';
import wifi from './assets/wifi-icon.png';

// assets (app icons)
// import platformAI from './assets/app-icons/platform-app-icon.png';
import presenceAI from  './assets/app-icons/presence-app-icon.png';
import pullvaultAI from './assets/app-icons/pull-vault-app-icon.png';
import MTAAI from './assets/app-icons/mta-app-icon.png';

// styles
import { AppsContainer, ProductDesignContainer, ProductDesignHeader, HeaderLeft, HeaderRight } from './ProductDesign.styles.js'

// components
import AppIcon from './components/app-icon/AppIcon'
import ElementWrapper from '../../components/element-wrapper/ElementWrapper.js';

export default function ProductDesign() {

  const navigate = useNavigate()
  const [date, setDate] = useState(new Date())
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const refreshClock = () => {
    console.log('new time')
    setDate(new Date())
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000)
    console.log('useEffect ran')
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [])

  return (
    <>
      <ProductDesignContainer>
        <ProductDesignHeader>
          <HeaderLeft>
            <div onClick={() => navigate('/')} className='ios-back-btn'>
              <img src={chevron} alt='back button icon' />
              <h4>Back</h4>
            </div>
            <h5 id='clock'>{date.toLocaleTimeString()}</h5>
            <h5 id='calendar-date'>{days[date.getDay()]} {months[date.getMonth()]} {date.getDate()}</h5>
          </HeaderLeft>
          <HeaderRight>
            <img className='wifi-icon-container' src={wifi} alt='wifi icon' />
            <h5>100%</h5>
            <img src={battery} alt='battery icon'/>
          </HeaderRight>
        </ProductDesignHeader>

        <AppsContainer>
          <p>Scroll &#x2190; &#x2192; and tap icons to view projects </p>
        <ElementWrapper delay={0.2}>
            <div onClick={() => navigate('mta')}>
              <AppIcon appIcon={MTAAI} name='MTA'/>
            </div>
          </ElementWrapper>
          <ElementWrapper delay={0.3}>
            <div onClick={() => navigate('pull-vault')}>
              <AppIcon appIcon={pullvaultAI} name='Pull Vault'/>
            </div>
          </ElementWrapper>
          <ElementWrapper delay={0.4}>
            <div onClick={() => navigate('presence')}>
              <AppIcon appIcon={presenceAI} name='presence'/>
            </div>
          </ElementWrapper>
          {/* <ElementWrapper delay={0.5}>
            <div onClick={() => navigate('platform')}>
              <AppIcon appIcon={platformAI} name='Platform'/>
            </div>
          </ElementWrapper> */}
        </AppsContainer>
      </ProductDesignContainer>
      <Outlet />
    </>
  )
}
